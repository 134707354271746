import { defineMessages } from 'react-intl'

export interface subscriptionFrequencyMessage {
  id: string
  defaultMessage: string
}

export const subscriptionFrequencyMessages = defineMessages({
  TWO_WEEKS: {
    id: 'TWO_WEEKS',
    defaultMessage: 'Ships Every 2 weeks',
  },
  FOUR_WEEKS: {
    id: 'FOUR_WEEKS',
    defaultMessage: 'Ships Every 4 weeks',
  },
  EIGHT_WEEKS: {
    id: 'EIGHT_WEEKS',
    defaultMessage: 'Ships Every 8 weeks',
  },
  THREE_MONTHS: {
    id: 'THREE_MONTHS',
    defaultMessage: 'Ships Every 3 months',
  },
  SIX_MONTHS: {
    id: 'SIX_MONTHS',
    defaultMessage: 'Ships Every 6 months',
  },
})

export default subscriptionFrequencyMessages
