// @ts-nocheck
import * as Types from '@src/types/graphql-types';

import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
const defaultOptions = {} as const;
export type GetSubscriptionFrequenciesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetSubscriptionFrequenciesQuery = { __typename?: 'Query', subscriptionFrequencies: Array<string> };


export const GetSubscriptionFrequenciesDocument = gql`
    query GetSubscriptionFrequencies {
  subscriptionFrequencies
}
    `;
export type GetSubscriptionFrequenciesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetSubscriptionFrequenciesQuery, GetSubscriptionFrequenciesQueryVariables>, 'query'>;

    export const GetSubscriptionFrequenciesComponent = (props: GetSubscriptionFrequenciesComponentProps) => (
      <ApolloReactComponents.Query<GetSubscriptionFrequenciesQuery, GetSubscriptionFrequenciesQueryVariables> query={GetSubscriptionFrequenciesDocument} {...props} />
    );
    

/**
 * __useGetSubscriptionFrequenciesQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionFrequenciesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionFrequenciesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionFrequenciesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSubscriptionFrequenciesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubscriptionFrequenciesQuery, GetSubscriptionFrequenciesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetSubscriptionFrequenciesQuery, GetSubscriptionFrequenciesQueryVariables>(GetSubscriptionFrequenciesDocument, options);
      }
export function useGetSubscriptionFrequenciesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubscriptionFrequenciesQuery, GetSubscriptionFrequenciesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetSubscriptionFrequenciesQuery, GetSubscriptionFrequenciesQueryVariables>(GetSubscriptionFrequenciesDocument, options);
        }
export type GetSubscriptionFrequenciesQueryHookResult = ReturnType<typeof useGetSubscriptionFrequenciesQuery>;
export type GetSubscriptionFrequenciesLazyQueryHookResult = ReturnType<typeof useGetSubscriptionFrequenciesLazyQuery>;
export type GetSubscriptionFrequenciesQueryResult = ApolloReactCommon.QueryResult<GetSubscriptionFrequenciesQuery, GetSubscriptionFrequenciesQueryVariables>;