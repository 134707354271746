import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Theme } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import messages from '@utils/messages'
import clsx from 'clsx'

interface NewFeatureTagProps {
  className?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  alertChip: {
    display: 'inline-flex',
    alignItems: 'center',
    height: 20,
    fontSize: theme.typography.pxToRem(11),
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0.5,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    textTransform: 'uppercase',
    borderRadius: 3,
    padding: '0 6px',
    marginLeft: 6,
  },
}))

const NewTag: React.FC<NewFeatureTagProps> = (props) => {
  const classes = useStyles()

  return (
    <span className={clsx(classes.alertChip, props.className)}>
      {props.children || <FormattedMessage {...messages.NEW} />}
    </span>
  )
}

export default NewTag
